.mainContent {
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 2rem;
}

.mainContentList {
  margin-top: 2rem !important;
}

.paperList {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
}

.paperListInactive {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  opacity: 0.4;
}

.dropdown {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  height: 50px;
  line-height: 24px;
  width: 11rem;
}

.dropdownSelect {
  align-items: center;
  background: #f5f4f4;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: space-between;
  line-height: 24px;
  margin-right: 20px;
  width: 150px;
}

.titleItems {
  align-items: center;
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: flex-start;
}

.iconArrow {
  margin-left: -2rem;
}
.searchInput {
  margin-left: 2rem;
}
.infoVacancy {
  align-items: center;
  display: flex;
  padding: 0 2rem;
  flex: 1 !important;
}
.location {
  padding-left: 5px !important;
}
.items {
  display: flex;
  padding-right: 15rem;
}
.disable {
  opacity: 0.2;
}
.box {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.iconSearch {
  color: #000000;
  height: 3rem;
  padding-left: 1rem;
  width: 2rem;
}
.box > input {
  border: none;
  border-radius: 8px;
  flex: 1;
  font-size: 18px;
  height: 40px;
  outline: none;
  padding-left: 1rem;
}

.vacTitle {
  color: #2d3436 !important;
  /* font-family: 'Inter'; */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  letter-spacing: 0.25px !important;
  line-height: 24px !important;
}

.btn {
  background-color: transparent;
}

.btn:hover {
  background-color: transparent;
}

.options {
  color: rgba(45, 52, 54, 0.6);
  display: flex !important;
}

.cvBtn {
  background-color: #d2eaff;
  border-radius: 4px;
  margin: 2rem;
  margin-top: 10px;
  height: 40px;
  width: 90%;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
}

.cvBtn:hover {
  background-color: #d2eaff;
}

.listItem {
  justify-content: space-between !important;
}

.notFoundText {
  text-align: center !important;
  margin: 3rem !important;
  font-weight: bold !important;
  font-size: 20px;
}

.numberOfApplicationsLabel {
  display: flex !important;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: center;
}

/* Vacancy Dates */

.vacancyListedDates {
  width: 100%;
  text-transform: uppercase;
  display: block;
  padding: 0 2rem;
  margin: 5px 0 0 0;
}

.vacancyListedDatesCreated, .vacancyListedDatesClosed, .vacancyListedDatesOpen {
  display: inline-block;
  background: #f3f3f3;
  color: #858585;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 11px !important;
  line-height: 11px !important;
}

.vacancyListedDatesClosed {
  background: #f8d7da;
  color: #a32b37;
}

.vacancyListedDatesClosedInactive {
  display: none;
}

.vacancyListedDatesCreated {
  background: #d4edda;
  color: #24883b;
}

.vacancyListedDatesCreated > span, .vacancyListedDatesClosed > span, .vacancyListedDatesOpen > span {
  font-weight: 600;
}

.vacancyListedDates > p {
  margin: 0 5px 0 0;
}

.vacancyListedDates > span:last-child {
  margin: 0;
}

@media (max-width: 600px) {
  .infoVacancy {
    display: inline !important;
    padding: 0 0.5rem !important;
  }

  .vacTitle {
    font-size: 0.8rem !important;
    line-height: normal !important;
  }

  .location {
    font-size: 0.7rem !important;
  }

  .numberOfApplicationsLabel {
    font-size: 0.7rem !important;
  }
}
