.messageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.acceptBtn {
  width: 8rem !important;
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-top: 1rem !important;
}
