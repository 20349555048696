.container {
  align-items: center;
  background-color: #d2eaff;
  display: flex;
  height: 602px;
  margin-top: -60px;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 70px;
}
.btn {
  text-decoration: none !important;
  background: #1b2124 !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29) !important;
  color: white !important;
  font-weight: lighter !important;
  font-size: 3rem !important;
  height: 45px !important;
  line-height: 24px !important;
  margin-top: 30px !important;
  width: 15rem !important;
  padding: 0 !important;
  margin: 2rem 2rem 0 2rem !important;
}

.btn:hover {
  background: #1b2124;
}

.link {
  text-decoration: none;
  color: white;
  font-weight: lighter;
  font-size: 1.1rem !important;
  width: 100%;
  justify-content: center;
  padding: 11px 0;
  text-transform: none !important;
  letter-spacing: 0.502832px !important;
}
.textTitle {
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 36px !important;
  line-height: 42px !important;
}

.welcomeMessage {
  font-style: normal !important;
  font-size: 42px !important;
  line-height: 38px !important;
  margin-top: 2rem !important;
}

.btnContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.loginBtn {
  background-color: #1b2124 !important;
  color: #fff !important;
  text-transform: none !important;
  text-decoration: none !important;
  font-weight: lighter !important;
  font-size: 1.1rem !important;
  height: 45px !important;
  line-height: 24px !important;
  width: 15rem !important;
  padding: 0 !important;
  margin: 2rem 2rem 0 2rem !important;
}

/* MOBILE */
@media (max-width: 600px) {
  .container {
    width: 100%;
    padding-top: 3rem;
    height: 100vh;
    min-height: 731px;
  }

  .textTitle,
  .welcomeMessage {
    text-align: center !important;
  }

  .btnContainer {
    display: flex;
    justify-content: center;
  }

  .btn,
  .loginBtn {
    width: 200px !important;
    font-size: 0.9rem !important;
  }

  .link {
    font-size: 0.9rem !important;
  }
}
