.search {
  width: 100%;
}

.btnCreate {
  width: 169px;
  height: 40px;
  background-color: #1b2124 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

@media (max-width: 600px) {
  .btnCreate {
    width: 100% !important;
    margin-top: 1rem !important;
  }
}
