.cvBtn {
  background-color: #d2eaff !important;
  border-radius: 4px !important;
  margin: 2rem !important;
  margin-top: 10px !important;
  height: 40px;
  width: 90%;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
}

.cvBtn:hover {
  background-color: #d2eaff;
}

.cvBtn:disabled {
  opacity: 0.5;
}

.modalTitle {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.downloadBtn {
  background-color: #d2eaff;
  border-radius: 4px;
  margin-left: 80px;
  margin-top: 30px;
  height: 40px;
  width: 70%;
}

.downloadBtn:hover {
  background-color: #d2eaff;
}

.downloadLink {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

.inputGroup {
  width: 90% !important;
  height: auto;
  position: relative !important;
  border-radius: 4px;
  margin: 1rem !important;
}

.input {
  background-color: transparent !important;
  width: 75% !important;
  height: auto !important;
  color: #644646 !important;
  /* font-size: 16px; */
  line-height: 24px;
  /* font-family: 'Mulish'; */
  margin-bottom: 10px !important;
}
