.stepsContainer {
  background-color: #f5f5f5;
  height: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.stepTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 38px;
  color: #000000;
  text-align: center;
  margin-bottom: 100px;
}

.contentInfo {
  width: 20%;
  margin: 0 3rem;
}

.contentInfo > p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}
.stepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.stepSubTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}
.stepOne {
  background-color: rgba(240, 124, 242, 0.5);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-top: -28px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.stepTwo {
  background-color: rgba(255, 220, 0, 0.5);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-top: -28px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.stepThree {
  background-color: rgba(61, 152, 231, 0.5);
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

@media (max-width: 600px) {
  .contentInfo {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
  }

  .contentInfo:not(:last-child) {
    margin-bottom: 2rem;
  }

  .stepsContainer {
    padding: 4rem 0;
  }
}
