.centerText {
  text-align: center !important;
}

.centerText,
.paragraph {
  margin-bottom: 1rem !important;
}

.paragraph {
  text-align: justify !important;
}
