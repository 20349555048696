.list {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 1rem 0 !important;
}

.applyBtn {
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  float: right !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.select {
  max-width: 250px;
  margin: 20px 0;
} 
