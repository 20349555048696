.link {
  text-decoration: none;
  color: black;
}

.activeLink {
  font-weight: bolder;
}

.navbarMaster {
  padding: 0 7rem !important;
}

.divNabContainer {
  position: absolute;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .navbar,
  .navbarMaster {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .navbarMaster {
    padding: 0 !important;
  }
}

@media (max-width: 500px) {
  .navbar,
  .navbarMaster {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 70px;
  }

  .navbarMaster {
    padding: 0 !important;
  }
}
