.paper {
  width: 600px;
  position: absolute;
  top: 10%;
  left: calc(50% - 360px);
  padding: 30px;
}

.formControl {
  width: 100%;
  margin-bottom: 25px !important;
}
