.btnHeader {
  width: 169px;
  height: 40px;
  background-color: #1B2124 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.btnHeader:disabled {
  opacity: 0.5;
}

@media (max-width: 600px) {
  .headerText {
    font-size: 1.5rem !important;
  }
}
