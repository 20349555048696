.mainContent {
  background-color: #f9ebb2;
  height: 30vh;
  padding-bottom: 3rem;
  padding-left: 6rem;
  padding-right: 8rem;
  margin-top: -40px;
}

.title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  /* line-height: 38px; */
}

.subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  /* line-height: 38px; */
}

.container {
  margin-top: 50px;
  margin-left: 660px;
}

.icon {
  margin-left: -25px;
  color: #ffeb59;
}

.firstTitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  margin-left: -130px;
}

.secondTitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  margin-left: -30px;
}

.termAndConditionsCheckbox {
  margin-top: 1rem !important;
  margin: auto;
}

.options {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4rem !important;
}

.confirmBtn {
  text-transform: capitalize !important;
  color: #fff !important;
  background: #000 !important;
  width: 128px !important;
  height: 40px !important;
}

.privacyContainer {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap;
}

.privacyLabel {
  margin-top: 1rem !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.message {
  margin-bottom: 3rem !important;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: #fff;
  width: 50%;
}
.paper p {
  text-align: center;
}

@media (max-width: 600px) {
  .paper {
    width: 80%;
  }

  .privacyLabel {
    text-align: center;
  }

  .termAndConditionsCheckbox {
    margin-right: 20px;
  }
}

@media (max-width: 400px) {
  .termAndConditionsCheckbox {
    margin-left: 5px !important;
    margin-right: 30px !important;
  }
}
