.formControl {
  width: 100%;
  margin-bottom: 25px !important;
}

.navegationBtn {
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}
