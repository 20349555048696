.formControl {
  width: 100%;
  margin-bottom: 25px !important;
}

.paper {
  width: 37.5rem;
  position: absolute;
  top: calc(50% - 260px);
  left: calc(50% - 360px);
  padding: 30px;
}

.loginBtn {
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  float: right !important;
  width: 9rem;
}

.forgetPasswordBtn {
  text-transform: capitalize !important;
  float: right !important;
  width: 12rem;
}

.loginBtn:disabled {
  opacity: 0.5;
}

.videoPlayer {
}

.videoPlayerHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cancelBtn {
  cursor: pointer;
  color: rgb(107, 107, 107) !important;
}

.cancelBtn:hover {
  color: black;
}

.cancelBtnIcon {
  margin: 0;
  padding: 0;
}

/* MOBILE */
@media (max-width: 600px) {
  .paper {
    width: 15rem;
    left: calc(50% - 150px);
  }
}
