.header {
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 15px;
}

.link {
  text-decoration: none;
  color: black;
}

.activeLink {
  font-weight: bold;
}

/* MOBILE */
@media (max-width: 600px) {
  .options {
    display: flex !important;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    width: 90vw !important;
    display: flex !important;
    justify-content: space-around !important;
    margin-top: 1rem;
  }
}
