.description {
  white-space: pre-line !important;
}

.label {
  margin-top: 1rem !important;
  margin-bottom: 3rem !important;
}

.divider {
  margin-bottom: 2rem !important;
}

.termAndConditionsCheckbox {
  margin-top: 1rem;
}

.confirmBtn {
  text-transform: capitalize !important;
  color: #fff !important;
  background: #000 !important;
  width: 128px !important;
  height: 40px !important;
}

.confirmBtn:hover {
  background: #000 !important;
}

.confirmBtn:disabled {
  opacity: 0.5;
}

.backBtn {
  text-transform: capitalize !important;
  width: 128px !important;
  height: 40px !important;
  margin-right: 1rem !important;
}

.backBtn:disabled {
  opacity: 0.5;
}

.options {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4rem !important;
}

.privacyContainer {
  display: flex !important;
  align-items: center !important;
}

.privacyLabel {
  margin-top: 1rem !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: #fff;
  width: 50%;
}
.paper p {
  text-align: center;
}
@media (max-width: 600px) {
  .paper {
    width: 80%;
  }
}
