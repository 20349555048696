.footerUp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px !important;
  margin-top: 30px !important;
}

.footerDown {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.footer {
  width: 100%;
  position: absolute;
  margin-top: 1rem;
  margin-bottom: 1rem;
  bottom: 0px;
}

.privacyContainer {
  display: flex !important;
}

.privacyLabel {
  cursor: pointer !important;
  text-decoration: underline !important;
  margin-right: 1rem !important;
  font-size: smaller !important;
}
