/* Vacancy Dates */

.vacancyDates {
  width: 100%;
  text-transform: uppercase;
  display: block;
  padding: 0 2rem;
  margin: 5px 0 0 0;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 15px;
}

.vacancyDatesCreated, .vacancyDatesClosed, .vacancyDatesOpen {
  display: inline-block;
  background: #f3f3f3;
  color: #858585;
  padding: 5px 8px;
  border-radius: 15px;
  font-size: 13px !important;
  line-height: 13px !important;
}

.vacancyDatesClosedInactive {
  display: none;
}

.vacancyDatesCreated {
  background: #d4edda;
  color: #24883b;
}

.vacancyDatesCreated > span, .vacancyDatesClosed > span, .vacancyDatesOpen > span {
  font-weight: 600;
}

.vacancyDates > p {
  margin: 0 15px 0 0;
}

.vacancyDates > span:last-child {
  margin: 0;
} 