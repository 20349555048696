.mainContentFooter {
  margin: 2rem 6rem;
}

.footerUp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-top: 20px;
}

.footerDown {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
  margin-bottom: 10px;
}
.footerDivider {
  background-color: black;
  width: 100%;
  margin-top: 5px;
}
.btnFooter {
  height: 40px;
  background: #1b2124;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: white;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  line-height: 24px;
  width: max-content;
  cursor: pointer;
  text-transform: initial;
}
.btnFooter:hover {
  background: #1b2124;
}
.lineFooter {
  height: 1px;
  background-color: #000000;
  padding: 0 50px;
  width: 100%;
}

.titleFooter {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: normal;
  font-size: 28.9231px;
  line-height: 36px;
}
.copyRight {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.modalLogin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacyContainer {
  display: flex !important;
}

.privacyLabel {
  cursor: pointer !important;
  text-decoration: underline !important;
  margin-right: 1rem !important;
  font-size: smaller !important;
}
