.mainContent {
  background-color: #c5f4e0;
  height: 30vh;
  padding-bottom: 3rem;
  padding-left: 6rem;
  padding-right: 8rem;
}
.title {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 38px;
}

.btnLike {
  background-color: rgba(13, 181, 131, 0.15);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: #0db583;
  height: 40px;
  width: 200px;
}

.btnLike:hover {
  background-color: rgba(27, 33, 36, 0.1);
  transition: all 0.3s;
}

.btnDislike {
  background-color: rgba(181, 13, 63, 0.15);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: #b50d3f;
  height: 40px;
  width: 200px;
}

.btnDislike:hover {
  background-color: rgba(27, 33, 36, 0.1);
  transition: all 0.3s;
}

.typo {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.container {
  margin-top: 50px;
  margin-left: 660px;
}

.icon {
  margin-left: -25px;
  color: #0db583;
}

.firstTitle {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  margin-left: -130px;
}

.secondTitle {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  margin-left: -30px;
}

.mainContentCard {
  margin-top: 2rem;
}

.boxItems {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.textName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.text {
  /* font-family: 'Inter'; */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 28px !important;
  line-height: 36px !important;
  margin-left: 2rem !important;
}

.applicationStatus {
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  margin-left: 4rem !important;
}

.videoPlayer {
  display: flex;
  padding: 0 1rem !important;
}

.divDetail {
  width: 100%;
  margin-left: 3rem;
}

.cardContainer {
  padding: 1rem 5rem 5rem 5rem;
}

.downloadBtn {
  background-color: #d2eaff !important;
  border-radius: 4px !important;
  margin-left: 2em !important;
  height: 40px !important;
  width: 90% !important;
}

.downloadBtn:hover {
  background-color: #d2eaff;
}

.downloadLink {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

.btnAddVacancy {
  display: flex;
  align-items: center;
  background: #1b2124;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: white;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 1rem;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  margin-top: 2rem;
  margin-left: 5rem;
}

.btnAddVacancy:hover {
  background: #1b2124;
}

.inputGroup {
  width: 90% !important;
  height: auto !important;
  position: relative !important;
  border-radius: 4px !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-right: 2rem !important;
}

@media (max-width: 600px) {
  .videoPlayer {
    display: block;
    padding: 0;
  }
  .divDetail {
    width: 100%;
    margin-left: 0;
  }
}
