.container {
  display: flex;
  align-items: center;
  height: 30vh;
  width: 55%;
}

.grid {
  margin-top: -50px;
}
.titleContact {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 38px;
}
.textContact {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}

@media (max-width: 600px) {
  .container {
    margin-top: 3rem !important;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100% !important;
  }

  .wrapper {
    margin: 0 !important;
  }
}
