.termAndConditionsCheckbox {
  margin-top: 1rem !important;
  margin: auto;
}

.options {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4rem !important;
}

.confirmBtn {
  text-transform: capitalize !important;
  color: #fff !important;
  background: #000 !important;
  width: 128px !important;
  height: 40px !important;
}

.inputFile {
  display: none;
}

.message {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: #fff;
  width: 50%;
}
.paper p {
  text-align: center;
}
.root {
  width: 95%;
  padding: 3%;
  margin-bottom: 35px;
}
.analyzing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  justify-content: center;
  background: #ffff;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .paper {
    width: 80%;
  }
}
