.container {
  margin-top: 20px;
  width: 200px;
}

.content {
  display: flex;
  flex-direction: row;
}

.centerPage {
  display: flex;
  justify-content: center;
}

.arrow {
  margin-top: 4px;
}

.contentDoubleArrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.currentPage {
  font-size: 17px;
  color: #626262;
  margin: 0 20px;
}

.leftPages {
  display: flex;
  flex-direction: row;
  font-size: 17px;
  justify-content: center;
}

.rightPages {
  display: flex;
  flex-direction: row;
  font-size: 17px;
  justify-content: center;
}

.uniqPage {
  margin: 0 10px;
}

.page {
  cursor: pointer;
  color: #878787;
  -webkit-transition-property: color;
  -webkit-transition-duration: 300ms;
}

.page:hover {
  color: #0053b3;
  font-weight: bold;
}

.iconEnabled {
  display: inline-block;
  position: relative;
  top: -6px;
  cursor: pointer;
  color: #000;
  -webkit-transition-property: color;
  -webkit-transition-duration: 300ms;
}

.iconDisabled {
  display: inline-block;
  position: relative;
  top: -6px;
  color: #ddd;
}

.iconEnabled:hover {
  cursor: pointer;
  color: #0053b3;
}

.dataInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.paginationContainer {
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.itemsPerPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;
  width: fit-content;
}
