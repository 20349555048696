.container {
  background-color: #c5f4e0;
  height: 30vh;
  padding-bottom: 3rem;
  padding-left: 6rem;
  padding-right: 8rem;
}
.title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 38px;
}
.button {
  display: flex;
  align-items: center;
  background: #1b2124;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: white;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 1rem;
  text-decoration: none;
}

.formContainer {
  padding: 10px;
}

.editBtn {
  width: 169px;
  background-color: #1B2124 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.sectionTitle {
  margin-bottom: 1rem !important;
  font-size: 20px !important;
}
