.inputGroup {
  width: 90%;
  height: auto;
  position: relative;
  border: 1px solid darkgray;
  border-radius: 4px;
  margin: 2rem;
}

.inputGroup:hover,
.inputGroup:focus-within {
  border-color: #1417c9;
}
.inputGroup input {
  padding: 16px 14px;
  border: none;
  background-color: transparent;
  width: 100%;
  height: auto;
  color: #644646;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Mulish';
}
.inputGroup input:focus {
  color: grey;
}
.inputGroup label {
  position: absolute;
  background-color: transparent;
  padding: 0px 8px;
  line-height: 24px;
  top: -12px;
  left: 16px;
  bottom: auto;
  color: #777777;
  background-color: white;
  cursor: text;
  font-family: 'Mulish';
}

.inputGroup select {
  padding: 16px 14px;
  border: none;
  background-color: transparent;
  width: 100%;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Mulish';
}
.inputGroup select:focus {
  color: grey;
}
.inputGroup label {
  position: absolute;
  background-color: transparent;
  padding: 0px 8px;
  line-height: 24px;
  top: -12px;
  left: 16px;
  bottom: auto;
  color: #777777;
  background-color: white;
  cursor: text;
  font-family: 'Mulish';
}
.inputGroup:hover label {
  color: #000000;
}
.selectMain {
  appearance: none;
}
.iconSelect {
  position: absolute;
  right: 0;
  top: 30%;
  margin-right: 1.5rem;
  width: 1.4rem;
  height: 1.4rem;
}
.boxButton {
  display: flex;
  justify-content: center;
}
.titleHead {
  padding-top: 2rem;
  padding-left: 2rem;
}

.progress {
  color: gray;
}

.checkbox {
  margin-left: 5%;
}

.checkbox:hover {
  background-color: transparent;
}

.input {
  padding: 16px 14px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
  width: 75%;
  height: auto;
  color: #644646;
  font-size: 16px;
  line-height: 24px;
  /* font-family: 'Mulish'; */
  margin-bottom: 10px;
  border: solid 1px rgba(0, 0, 0, 0.3) !important;
}

.removeBtn {
  color: #f3c5f4;
}

.removeBtn:hover {
  color: #f36a8a;
  background-color: transparent;
  transition: all 0.5s;
}

.addBtn {
  color: #abd0ef;
  border: 1px solid #003564;
  margin-left: 20px;
  margin-right: 20px;
}

.addBtn:hover {
  color: #003564;
  background-color: #abd0ef;
  border: 1px solid #abd0ef;
  transition: all 0.5s;
}

.typo {
  padding: 16px 14px;
  width: 85%;
  height: auto;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  font-family: 'Mulish';
  margin-left: 15px;
}

.radioGroup {
  margin-left: 25px;
}

.addQuestion {
  background-color: #d2eaff;
  border-radius: 4px;
  margin-top: 32px;
  margin-left: 35%;
  margin-bottom: 20px;
}

.addQuestion:hover {
  background-color: #d2eaff;
}

.typo {
  padding: 16px 14px;
  width: 85%;
  height: auto;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  font-family: 'Mulish';
  margin-left: 15px;
}

.radioGroup {
  margin-left: 25px;
}

.removeBtn {
  color: #f3c5f4;
}

.removeBtn:hover {
  color: #f36a8a;
  background-color: transparent;
  transition: all 0.5s;
}
