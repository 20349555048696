.search {
  width: 100%;
}

.unlinkBtn {
  width: 20%;
  height: 40px;
  background-color: #1b2124 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.selectAllCheckbox {
  margin-left: 1rem;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  .search {
    width: 100%;
    margin-top: 1rem !important;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}