.inputGroup {
  width: 90% !important;
  height: auto;
  position: relative !important;
  border-radius: 4px;
  margin: 1rem !important;
}

.textSection {
  margin-left: 1rem;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 36px !important;
  margin-top: 2rem !important;
}

.areasSection {
  display: flex !important;
  gap: 1rem !important;
  margin-top: 1rem !important;
}
