.paper {
  width: 600px;
  position: absolute;
  top: 10%;
  left: calc(50% - 360px);
  padding: 30px;
}

.formControl {
  width: 100%;
  margin-bottom: 25px !important;
}

.formWrapper {
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 15px;
  overflow-y: scroll;
  max-height: 25rem;
}

@media (max-width: 600px) {
  .paper {
    width: 17rem;
    left: calc(50% - 166px);
  }
}
