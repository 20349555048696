.paper {
  width: 600px;
  position: absolute;
  top: 10%;
  left: calc(50% - 360px);
  padding: 30px;
}

.divider {
  margin-bottom: 2rem !important;
}

.btnGroup {
  float: right;
}

.applyBtn {
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  width: 124px;
}

.applyBtn:hover {
  background: #000 !important;
  color: #fff !important;
}

.cancelBtn {
  text-transform: capitalize !important;
  margin-right: 1rem;
  width: 124px;
}

.title {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-bottom: 2rem !important;
}

.formWrapper {
  max-height: 30rem;
  overflow-y: scroll;
}
