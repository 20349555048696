.btn {
  background-color: transparent;
}

.btn:hover {
  background-color: transparent;
}

.dropdown {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  height: 50px;
  line-height: 24px;
  width: 11rem;
}

.dropdownSelect {
  align-items: center;
  background: #e5e5e5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: space-between;
  line-height: 24px;
  padding: 1rem;
  width: 150px;
}

.prueba {
  align-items: center;
  background: #e5e5e5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  height: 50px;
  line-height: 24px;
  width: 150px;
}

.infoVacancy {
  align-items: center;
  display: flex;
  padding: 0 2rem;
}

.location {
  padding-left: 5px;
}

.mainContent {
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 2rem;
}

.mainContentList {
  margin-top: 2rem !important;
}

.options {
  color: rgba(45, 52, 54, 0.6);
  display: flex !important;
}

.paperList {
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
}

.searchContainer {
  display: flex;
  margin-top: 40px;
  margin-left: 50px;
  margin-right: 20px;
  width: 90%;
}

.titleItems {
  align-items: center;
  display: flex;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: flex-start;
}

.vacTitle {
  color: #2d3436;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.25px;
  line-height: 36px;
}

.btnBanner {
  align-items: center;
  background: #1b2124;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
  color: white;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  padding: 1rem;
  width: 9rem;
  margin-left: 4.5rem;
  text-decoration: none;
}

.btnBanner:hover {
  background: #1b2124;
}

.link {
  text-decoration: none;
  color: white;
  font-weight: lighter;
  font-size: 14px;
  text-transform: capitalize;
}

.searchInput {
  margin-left: 2rem;
}

.box {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.iconSearch {
  color: #000000;
  height: 3rem;
  padding-left: 1rem;
  width: 2rem;
}
.box > input {
  border: none;
  border-radius: 8px;
  flex: 1;
  font-size: 18px;
  height: 40px;
  outline: none;
  padding-left: 1rem;
}

.progress {
  margin-top: 2rem;
}

.notFoundText {
  text-align: center !important;
  margin: 3rem !important;
  font-weight: bold !important;
  font-size: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
}
