.inputGroup {
  width: 90% !important;
  height: auto;
  position: relative !important;
  border-radius: 4px;
  margin: 1rem !important;
}

.inputIcons {
  width: 50%;
  height: auto;
  position: relative !important;
  border-radius: 4px;
  margin: 1rem !important;
}

.Icons {
  margin-right: 10px;
}

.textSection {
  margin-left: 1rem;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 36px !important;
  margin-top: 2rem !important;
}

.inputAsLink * {
  cursor: pointer !important;
}

.inputAsLink div {
  display: block;
}

@media (max-width: 600px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .videoPlayer {
    width: 100% !important;
  }
}
