.cvBtn {
  background-color: #d2eaff !important;
  border-radius: 4px !important;
  margin: 2rem !important;
  margin-top: 10px !important;
  height: 40px;
  width: 90%;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
}

.cvBtn:hover {
  background-color: #d2eaff;
}

.downloadLink {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #161616;
  text-decoration: none;
}