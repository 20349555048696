.paper {
  width: 600px;
  position: absolute;
  top: 5%;
  left: calc(50% - 360px);
  padding: 30px;
}

.modalTitle {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 32px !important;
  margin-bottom: 2rem !important;
}

.modalMessage {
  padding: 1rem 2rem !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 25px !important;
  /* or 156% */
  letter-spacing: 0.5px !important;
  margin-bottom: 3rem !important;
}

.footer {
  float: right;
  margin-top: 1rem;
}

.cancelBtn {
  width: 128px !important;
  background: #EDEDED !important;
  margin-right: 1rem !important;
  text-transform: capitalize !important;
}

.confirmBtn {
  width: 128px !important;
  background: #DAC7E0 !important;
  text-transform: capitalize !important;
}

.confirmBtn:hover { background: #DAC7E0 !important; }

@media (max-width: 600px) {
  .paper {
    width: 17rem;
    left: calc(50% - 166px);
  }
}
