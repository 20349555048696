.btnVote {
  background: #000000 !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29) !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  height: 40px;
  width: 150px;
}

.btnVote:disabled { opacity: 0.5; }

.lastVoteBtn {
  background: #ffffff !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.29) !important;
  border-radius: 4px !important;
  color: #000000 !important;
  height: 40px;
  width: 150px;
}

.lastVoteBtn:disabled { opacity: 0.5; }

@media (max-width: 600px) {
  .btnVote,
  .lastVoteBtn {
    width: 80px;
  }
}
