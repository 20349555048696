.inputGroup {
  width: 90% !important;
  height: auto;
  position: relative !important;
  border-radius: 4px;
  margin: 1rem !important;
}

.plusIcon {
  font-size: 1rem !important;
}

.saveBtn {
  width: 18rem !important;
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  margin-left: 1rem !important;
  margin-top: 2rem !important;
}
