.paper {
  width: 600px;
  position: absolute;
  top: 5%;
  left: calc(50% - 360px);
  padding: 30px;
}

.formControl {
  width: 100%;
}

.footer {
  float: right;
  margin-top: 1rem;
}

.divider {
  margin-top: 2rem !important;
}

.sendBtn {
  width: 8rem;
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.title {
  margin-bottom: 2rem !important;
}

@media (max-width: 600px) {
  .paper {
    width: 17rem;
    left: calc(50% - 166px);
  }
}
