.container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.icon {
  font-size: 10rem !important;
  margin-bottom: 2rem;
}
