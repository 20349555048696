.MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.dropdown {
  align-items: center;
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  height: 50px;
  line-height: 24px;
  width: 11rem;
}

.dropdownCandidate {
  align-items: center;
  background: rgba(245, 244, 244, 0.5);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: space-between;
  line-height: 24px;
  width: 150px;
}

.dropdownUser {
  align-items: center;
  background: #e5e5e5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  justify-content: space-between;
  line-height: 24px;
  padding: 1rem;
  width: 120px;
}

.post {
  height: 60px;
  width: 85%;
  margin-bottom: 1rem;
  margin-left: 20px;
}

.postBtn {
  background-color: #d2eaff !important;
  border: none;
  border-radius: 4px !important;
  height: 40px;
  /* margin-top: 10px; */
  margin-left: 16px !important;
  margin-right: 16px;
  width: 15%;
}

.form {
  margin-top: 20px;
  display: flex;
  /* border: 1px solid gray; */
  border-radius: 10px;
  margin: 10px;
  height: 65px;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
}

.form > input {
  border: none;
  font-family: 'Mulish';
  font-weight: bold;
  font-size: medium;
  padding: 1rem;
}

.textField {
  border-radius: 50px;
  width: 75%;
  margin-left: 30px;
}

.removeBtn {
  color: #f3c5f4;
  margin-top: -20px;
}

.removeBtn:hover {
  color: #f36a8a;
  background-color: transparent;
  transition: all 0.5s;
}
