.search {
  width: 100%;
}

.searchButton {
  width: 13rem !important;
  background: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

@media (max-width: 600px) {
  .searchButton {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  .search {
    margin-top: 1rem !important;
    width: 100% !important;
  }
}
