.formControl {
  width: 100%;
  margin-bottom: 20px !important;
}

.label {
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
}

.divider {
  margin-bottom: 2rem !important;
}

.fileInput {
  display: none;
}

.cvButton {
  width: 100%;
}

.plusIcon {
  font-size: 1rem !important;
}
