.commentSection {
  width: 100%;
  background: #FCFCFC;
  margin: 1rem;
  border: solid 1px #EFEFEF;
  border-radius: 4px;
  padding: 1rem;
}

.commentText {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.commentInfo {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  float: right !important;
}

.commentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}