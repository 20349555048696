.applicationListItem {
  margin-top: 1rem !important;
  margin-bottom: 15px;
}

.textName {
  /* margin-top: 2px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
}

.containerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 1;
}

.addNote {
  margin-left: 20px;
  margin-right: 20px;
}

.message {
  margin: auto !important;
  margin-top: 6rem !important;
  text-align: center !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .infoWrapper {
    flex-direction: column !important;
  }
}
