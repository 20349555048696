.statusSelect {
  width: 11rem !important;
  background: #E5E5E5 !important;
  border: none !important;
  outline: none;
  font-weight: bold !important;
  border-radius: 10px !important;
}

.statusChange {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.statusText {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .statusSelect {
    width: 7rem !important;
    font-size: 0.8rem !important;
  }
}
