.applicationListItemCandidates {
  margin-top: 1rem !important;
  margin-bottom: 15px;
}

.textName {
  /* margin-top: 2px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 36px;
}

.containerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 1;
}

.addNote {
  margin-left: 20px;
  margin-right: 20px;
}

.message {
  margin: auto !important;
  margin-top: 6rem !important;
  text-align: center !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.innerIcons {
  display: inline-block;
  cursor: pointer;
  margin: 0.5rem 1rem;
}

.Icons {
  color: rgba(0, 0, 0, 0.4);
  font-size: 2rem !important;
}

.Icons:hover {
  color: black;
}

.IconsDisabled {
  color: rgba(0, 0, 0, 0.1);
  font-size: 2rem !important;
}

.infoWrapper {
  display: block !important;
  padding-top: 20px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.infoWrapperDetails {
  display: flex;
  width: 100%;
}

.leftData {
  text-align: left;
  flex-basis: 0;
  flex-grow: 1;
}

.rightData {
  padding: 0 36px 0 0;
  text-align: right;
  flex-basis: 0;
  flex-grow: 1;
}

.infoWrapperApplications {
  display: block;
  width: 100%;
  border-radius: 1rem;
  margin: 1rem 0 0 0;
  background: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.applicationsDetails {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.applicationsDetails span:nth-child(2) {
  display: inline-block;
  background: white;
  color: #858585;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 11px !important;
  line-height: 11px !important;
  margin: 0 1rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.applicationsDetails span:nth-child(3) {
  display: block;
  font-size: 12px !important;
  line-height: 20px !important;
  margin: 0.3rem 0 0 0;
  color: #858585;
}

.applicationsDetails:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.detailsContact {
  margin-right: 35px;
}

@media (max-width: 600px) {
  .infoWrapper {
    display: flex !important;
    flex-direction: column !important;
  }
}
