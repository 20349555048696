.search {
  width: 100%;
}

.searchOptions {
}

@media (max-width: 600px) {
  .searchOptions {
    gap: 20px;
    justify-content: center;
  }

  .searchOptions > div:nth-child(1) {
    order: 2;
  }
  .searchOptions > div:nth-child(2) {
    order: 1;
    max-width: 100%;
    flex-basis: 100%;
  }
  .searchOptions > div:nth-child(3) {
    order: 3;
  }
  .searchOptions > div:nth-child(4) {
    order: 4;
  }
  .searchOptions > div:nth-child(5) {
    order: 5;
  }
}
