.select {
  max-width: 250px;
  margin: 20px 0;
}

.search {
  width: 100%;
}

@media (max-width: 600px) {
  .searchOptions {
    gap: 20px;
    padding: 0 16px 16px 16px;
    justify-content: center;
  }

  .searchOptions > div:nth-child(1) {
    max-width: 100%;
    flex-basis: 100%;
  }
  .searchOptions > div:nth-child(2) {
  }
  .searchOptions > div:nth-child(3) {
  }
  .searchOptions > div:nth-child(4) {
  }
  .searchOptions > div:nth-child(5) {
  }
}
