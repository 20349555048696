.divider {
  margin-bottom: 2rem !important;
}

.optionLabel {
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
}

.areasSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.area,
.areaSelected {
  cursor: pointer;
  transition: all .2s;
}

.areaSelected {
  background: #000 !important;
  color: #fff !important;
}

.area:hover {
  background: rgba(0, 0, 0, 0.5) !important;
  color: #fff;
}
